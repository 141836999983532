import { useState } from 'react';
import { Typography, IconButton } from '@mui/material';

import { EmojiWrapper } from './style';

const EMOJI_LIST = ['🥶', '🤣', '😁', '😱', '😬', '🤯', '🥵', '🤡', '💀', '😲', '👀'];

const EmojiRandomizer = () => {
  const [currentEmoji, setCurrentEmoji] = useState(0);
  return (
    <EmojiWrapper>
      <IconButton
        size="small"
        onMouseEnter={() =>
          setCurrentEmoji((currentEmojiValue) => {
            if (currentEmojiValue === EMOJI_LIST.length - 1) {
              return 0;
            }
            return currentEmojiValue + 1;
          })
        }
      >
        <Typography>{EMOJI_LIST[currentEmoji]}</Typography>
      </IconButton>
    </EmojiWrapper>
  );
};

export default EmojiRandomizer;
