import { useState, useEffect, useMemo, createContext } from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// translations
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import { IUserProfileData, isUserAuthenticated } from 'api/auth';
import { THEME, ROOM_URL_PATH } from 'utils/constants';

// views
import Home from 'views/Home';
import Privacy from 'views/Privacy';
import Terms from 'views/Terms';
import Support from 'views/Support';
import Login from 'views/Login';
import Register from 'views/Register';
import Room from 'views/Room';
import Dashboard from 'views/Dashboard';
import Settings from 'views/Settings';
import NotFound from 'components/NotFound';

import englishTranslations from 'locales/en.json';
import frenchTranslations from 'locales/fr.json';
import deutchTranslations from 'locales/de.json';

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation: englishTranslations,
    },
    fr: {
      translation: frenchTranslations,
    },
    de: {
      translation: deutchTranslations,
    },
  },
});

interface IGlobalContext {
  userProfileData: IUserProfileData | null;
  setUserProfileData: React.Dispatch<React.SetStateAction<IUserProfileData | null>>;
  isLoggedIn: boolean;
  isFullyLoggedIn: boolean;
}

export const GlobalContext = createContext<IGlobalContext>(null);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: THEME.TEXT,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: THEME.ACCENT,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: 'rgba(83, 83, 95, .48)' },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: THEME.ACCENT,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-colorPrimary': {
            backgroundColor: THEME.ACCENT,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: THEME.TEXT,
        },
        indicator: {
          backgroundColor: THEME.ACCENT,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'color': THEME.TEXT,
          '&.Mui-selected': {
            color: THEME.ACCENT,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.1)',
            borderRadius: '.4rem',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.1)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: THEME.ACCENT,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:after': {
            borderBottom: `2px solid ${THEME.ACCENT}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: THEME.ACCENT,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,0.8)',
        },
      },
    },
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [userProfileData, setUserProfileData] = useState<IUserProfileData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const profileData = await isUserAuthenticated();
      setUserProfileData(profileData);
    };
    fetchData();
  }, []);

  /**
   * When the user is logged in (i.e either as a guest or associated with an account)
   */
  const isLoggedIn = useMemo(
    () => (userProfileData && Object.values(userProfileData).length > 0) || false,
    [userProfileData],
  );

  /**
   * When the user is logged in and not as a guest
   */
  const isFullyLoggedIn = useMemo(() => isLoggedIn && !userProfileData.is_guest, [isLoggedIn, userProfileData]);

  useEffect(() => {
    setIsLoading(false);
  }, [isLoggedIn]);

  return (
    <I18nextProvider i18n={i18next}>
      <GlobalContext.Provider value={{ userProfileData, setUserProfileData, isLoggedIn, isFullyLoggedIn }}>
        <ThemeProvider theme={theme}>
          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/app" component={() => (isFullyLoggedIn ? <Dashboard /> : <Redirect to="/login" />)} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              {/* <Route exact path="/support" component={Support} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route path={`${ROOM_URL_PATH}:id`} component={Room} />
              <Route
                exact
                path="/settings/:category"
                component={() => (isFullyLoggedIn ? <Dashboard children={<Settings />} /> : <Login />)}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </GlobalContext.Provider>
    </I18nextProvider>
  );
};

export default App;