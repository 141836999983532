import { useState, useEffect } from 'react';
import { Modal, Typography, Stack, Zoom } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, SecondaryButton } from 'style/common';
import { THEME } from 'utils/constants';
import { hexToRGBA } from 'utils/commonUtils';

import { Container } from './style';
import IConfirmationModal from './types';

const ConfirmationModal = (props: IConfirmationModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={props.isVisible}
      onClose={() => props.toggle(false)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Zoom timeout={100} unmountOnExit in={props.isVisible}>
        <Container>
          <Stack justifyContent="end" p={4} spacing={2} sx={{ borderRadius: '1rem' }}>
            <Typography variant="h6" fontWeight="bold">
              {props.title}
            </Typography>
            {props.message && <Typography variant="body1">{props.message}</Typography>}
          </Stack>
          <Stack
            direction="row"
            justifyContent={props.onCancelCbk == null ? 'center' : 'end'}
            p={2}
            spacing={2}
            sx={{
              bgcolor: hexToRGBA(THEME.PRIMARY, 0.6),
              borderBottomLeftRadius: '1rem',
              borderBottomRightRadius: '1rem',
            }}
          >
            <PrimaryButton onClick={props.onConfirmCbk} sx={{ fontSize: '14px' }}>
              {props.confirmText == null ? t('yes') : props.confirmText}
            </PrimaryButton>
            {props.onCancelCbk != null && (
              <SecondaryButton onClick={props.onCancelCbk} sx={{ fontSize: '14px' }}>
                {props.cancelText == null ? t('no') : props.confirmText}
              </SecondaryButton>
            )}
          </Stack>
        </Container>
      </Zoom>
    </Modal>
  );
};

export default ConfirmationModal;
