import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import CreateRoomSteps from 'components/CreateRoomSteps/CreateRoomSteps';

import { createRoom } from 'api/room';
import { ReactComponent as Blob1SVG } from 'assets/svg/home/Blob1.svg';
import { ReactComponent as Blob2SVG } from 'assets/svg/home/Blob2.svg';
import { ReactComponent as Blob3SVG } from 'assets/svg/home/Blob3.svg';
import { ReactComponent as PlusSVG } from 'assets/svg/home/Plus.svg';
import { ReactComponent as Ellipse } from 'assets/svg/home/Ellipse.svg';
import { ReactComponent as YRYCImage } from 'assets/svg/home/your-room-your-choice.svg';
import FilesLogoImage from 'assets/static/images/files-logo.png';
import { THEME } from 'utils/constants';

import SubscriptionDetails from './SubscriptionDetails';
import {
  CoreContainer,
  LightContainer,
  DarkContainer,
  StyledCoreButton,
  SpecialContainer,
  StyledLaptopSVG,
  StyledMonitorSVG,
  ParticleElementsWrapper,
} from './style';
import { CenteredDiv } from 'style/common';

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [gradientAngle, setGradientAngle] = useState(45);

  useEffect(() => {
    document.title = 'Synther | Watch Parties Simplified';
  }, []);

  const handleMouseMove = (e) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - buttonRect.left;
    const y = e.clientY - buttonRect.top;
    const centerX = buttonRect.width / 2;
    const centerY = buttonRect.height / 2;
    const distanceX = Math.abs(x - centerX);
    const distanceY = Math.abs(y - centerY);

    // Set the threshold distance (in pixels) for both x and y axis
    const threshold = 40;

    if (distanceX <= threshold && distanceY <= threshold) {
      return;
    }

    const angle = Math.atan2(y - centerY, x - centerX) * (180 / Math.PI);
    setGradientAngle(angle);
  };

  const createRoomAndRedirect = async () => {
    const redirectUrl = await createRoom();
    history.push(redirectUrl);
  };

  return (
    <>
      <Header />
      <CoreContainer>
        <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Blob1SVG style={{ position: 'absolute', top: 0, left: 0 }} width="20%" height="20%" />
          <Blob2SVG style={{ position: 'absolute', top: 0, right: 0 }} width="20%" height="20%" />
          <Blob3SVG style={{ position: 'absolute', bottom: 0, left: 0 }} width="20%" height="20%" />
          <StyledLaptopSVG />
          <StyledMonitorSVG />
          <ParticleElementsWrapper>
            <PlusSVG style={{ position: 'absolute', top: 150, left: '50%' }} width="40" />
            <PlusSVG style={{ position: 'absolute', right: 500, bottom: 40 }} width="50" />
            <PlusSVG style={{ position: 'absolute', top: 500, left: 50 }} width="20" />
            <Ellipse style={{ position: 'absolute', right: 200, bottom: 150 }} width="60" />
            <Ellipse style={{ position: 'absolute', top: 50, left: 100 }} width="40" />
            <Ellipse style={{ position: 'absolute', left: '55%', bottom: 150 }} width="40" />
          </ParticleElementsWrapper>
          <Stack>
            <Typography
              variant="h2"
              pb={4}
              pl={2}
              color={THEME.IMPORTANT_TEXT}
              textTransform="uppercase"
              align="left"
              fontWeight="bold"
              fontFamily="Montserrat"
              sx={{
                maxWidth: '35rem',
                textShadow: '8px 8px 24px rgba(0, 0, 0, .5)',
                zIndex: 1,
              }}
            >
              {t('simple_watch_parties')}
            </Typography>
            <CreateRoomSteps />
            <Typography
              variant="body1"
              fontWeight="bold"
              p={2}
              align="center"
              maxWidth="600px"
              sx={{
                textShadow: '1px 1px 24px rgba(0, 0, 0, 0.5)',
                zIndex: 1,
              }}
            >
              {t('landing_page_description')}
            </Typography>
            <CenteredDiv>
              <StyledCoreButton
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setGradientAngle(45)}
                angle={gradientAngle}
                onClick={createRoomAndRedirect}
              >
                {t('create_room_button')}
              </StyledCoreButton>
            </CenteredDiv>
          </Stack>
        </Container>
      </CoreContainer>
      <div>
        <SpecialContainer
          style={{
            flexDirection: 'column',
            padding: isMobileDevice ? '2rem' : '4rem',
          }}
        >
          <Container maxWidth="sm" style={{ zIndex: 1 }}>
            <Typography variant="h4" fontWeight="bold" align="center" textTransform="uppercase">
              {t('holds_up')}
            </Typography>
            <Typography variant="h6" align="center">
              {t('feature_brag')}
            </Typography>
          </Container>
          <SubscriptionDetails />
        </SpecialContainer>
        <DarkContainer>
          <YRYCImage width="600px" height="auto" />
          <Stack maxWidth="25rem">
            <Typography
              variant="h4"
              fontWeight="bold"
              textTransform="uppercase"
              sx={{
                maxWidth: '15rem',
              }}
            >
              {t('room_choice')}
            </Typography>
            <Typography variant="h6">{t('room_setting_explanation')}</Typography>
          </Stack>
        </DarkContainer>
        <LightContainer>
          <Stack maxWidth="25rem">
            <Typography
              variant="h4"
              fontWeight="bold"
              textTransform="uppercase"
              sx={{
                maxWidth: '15rem',
              }}
            >
              {t('share_content_together')}
            </Typography>
            <Typography variant="h6">{t('file_limit_msg')}</Typography>
          </Stack>
          <img
            src={FilesLogoImage}
            style={{ maxWidth: '600px' }}
            width="100%"
            height="auto"
            alt="A group of assorted files"
          />
        </LightContainer>
        <DarkContainer>
          <Stack alignItems="center" maxWidth="25rem">
            <Typography variant="h4" fontWeight="bold" align="center" textTransform="uppercase">
              {t('ready_to_sync_cta')}
            </Typography>
            <StyledCoreButton
              onMouseMove={handleMouseMove}
              onMouseLeave={() => setGradientAngle(45)}
              angle={gradientAngle}
              onClick={createRoomAndRedirect}
              sx={{ mt: 4 }}
            >
              {t('get_started_cta')}
            </StyledCoreButton>
          </Stack>
        </DarkContainer>
      </div>
      <Footer />
    </>
  );
};

export default Home;
