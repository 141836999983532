import { useEffect, useContext, useCallback } from 'react';
import { RoomContext } from '../Room';

const useSocket = (playerState, setPlayerState, playerRef, supressEvents) => {
  const { socketRef, roomRef, setCurrentUser } = useContext(RoomContext);

  const getHostPlayerState = useCallback(
    async (data) => {
      socketRef.current.emit('playerEvent', {
        currentMedia: playerState.currentMedia,
        source: playerState.source,
        tracks: playerState.tracks,
        hostID: playerState.hostID,
        playing: playerRef.current.player.isPlaying,
        time: playerRef.current.getCurrentTime(),
        playBackRate: playerState.playBackRate,
        toUser: data.fromUser,
      });
    },
    [playerState],
  );
  useEffect(() => {
    socketRef.current.on('getHostPlayerState', getHostPlayerState);
    return () => {
      socketRef.current.off('getHostPlayerState');
    };
  }, [getHostPlayerState]);

  useEffect(() => {
    // connected- if host, get initial state. if viewer, get host state (host & viewer)
    socketRef.current.on('connected', async (data) => {
      const host = data.room.hostSocketId;
      roomRef.current = data.room;
      setCurrentUser(data.currentUser);
      if (data.currentUser === host) {
        // get intial player state from server (host only)
        const update = {};
        update['currentMedia'] = data.room.media;
        update['source'] = data.room.src[data.room.media];
        update['tracks'] = data.room.tracks;
        update['host'] = true;
        update['hostID'] = host;
        setPlayerState((prevState) => ({ ...prevState, ...update }));
      } else {
        // get host's player state (viewer only)
        socketRef.current.emit('getHostPlayerState');
      }
      document.title = `Synther | ${data.room.media}`;
    });

    // playerEvent- captures updates from host (viewers only)
    socketRef.current.on('playerEvent', async (data: any) => {
      const update = {};
      supressEvents.current = true; // supress viewer events while state is updating
      if (data?.currentMedia != null) update['currentMedia'] = data.currentMedia; // unpack the payload
      if (data?.source != null) update['source'] = data.source;
      if (data?.tracks != null) update['tracks'] = data.tracks;
      if (data?.hostID != null) update['hostID'] = data.hostID;
      if (data?.playing != null) update['playing'] = data.playing;
      if (data?.time != null) {
        // time is not bound to state, need to seek explicitly
        update['time'] = data.time;
        playerRef.current.seekTo(data.time, 'seconds');
      }
      if (data?.playBackRate != null) {
        // playback rate not bound to state, need to set explicitly
        update['playBackRate'] = data.playBackRate;
        if (playerRef.current.getInternalPlayer() !== null) {
          playerRef.current.getInternalPlayer().setPlaybackRate(data.playBackRate);
        }
      }
      setPlayerState((prevState) => ({ ...prevState, ...update })); // update viewer state
      console.log(update, 'viewer update');
    });
  }, []);
};

export default useSocket;
