import { useState, useEffect, useRef, createContext } from 'react';

import useSocket from './SocketEvents';

import { Box } from '@mui/material';

import Peer from 'simple-peer';
import { Socket } from 'socket.io-client';
import { LoadingContainer } from 'style/common';

import Chat from 'components/Chatbox';
import VideoPlayer from './VideoPlayer';

import { ICachedRoom, IServerToClientEvents, IClientToServerEvents } from './types';
import { RoomContainer } from './style';

export enum MediaTypeEnum {
  YouTube = 'youtube',
  Twitch = 'twitch',
  Spotify = 'spotify',
  SoundCloud = 'soundcloud',
  WebTorrent = 'webtorrent',
  ScreenShare = 'screenshare',
}

interface IRoomContext {
  currentUser: string;
  setCurrentUser: (str: string) => void;
  socketRef: React.MutableRefObject<Socket<IServerToClientEvents, IClientToServerEvents>>;
  roomRef: React.MutableRefObject<ICachedRoom>;
}

export const RoomContext = createContext<IRoomContext>(null);

const Room = () => {
  const [loaded, setLoaded] = useState(false);

  const socketRef = useRef<Socket<IServerToClientEvents, IClientToServerEvents>>();
  const roomRef = useRef<ICachedRoom>();

  const [currentUser, setCurrentUser] = useState('');
  const [peers, setPeers] = useState<any>({});
  const [isMuted, toggleMute] = useState(true);

  // ui components
  const [isBrowseMediaViewVisible, toggleBrowseMediaView] = useState(false);
  const [isOverlayMenuVisible, toggleOverlayMenu] = useState(false);

  // create socket and join room
  useSocket(socketRef, setLoaded, roomRef)

  useEffect(() => {
    console.log(peers);
  }, [peers]);

  return (
    <RoomContainer>
      {loaded ? (
        <RoomContext.Provider value={{ currentUser, setCurrentUser, socketRef, roomRef }}>
          <Box display="flex" flexWrap="wrap" height="100%" sx={{ backdropFilter: 'blur(1rem)' }}>
            <VideoPlayer
              isMuted={isMuted}
              isBrowseMediaViewVisible={isBrowseMediaViewVisible}
              toggleOverlayMenu={toggleOverlayMenu}
              toggleBrowseMediaView={toggleBrowseMediaView}
            />
            <Chat
              toggleBrowseMediaView={toggleBrowseMediaView}
              toggleMute={toggleMute}
              isOverlayMenuVisible={isOverlayMenuVisible}
              setOverlayMenuVisible={toggleOverlayMenu}
            />
          </Box>
        </RoomContext.Provider>
      ) : (
        <LoadingContainer />
      )}
    </RoomContainer>
  );
};

export default Room;
