import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  IconButton,
  Slide,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Collapse,
} from '@mui/material';
import { Close, Search, PersonAdd, ExpandMore, ExpandLess } from '@mui/icons-material';

import { THEME } from 'utils/constants';
import { RoomContext } from 'views/Room/Room';
import { ICachedUser } from 'views/Room/types';
import CROWN_ICON_PNG from 'assets/icons/crown.png';
import GROUP_ICON_PNG from 'assets/icons/group.png';

import InviteFriendsModal from '../InviteFriendsModal';
import IParticipantList from './types';
import { CollapsiblePanel, StyledImg } from './style';

const Participant = (props: ICachedUser) => {
  return (
    <Stack margin={2} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt="Participant's Avatar"
            src={'/img/default_avatar.jpg'}
            sx={{
              width: '25px',
              height: '25px',
              backgroundColor: props?.avatar == null ? props.accent_color : 'none',
            }}
          />
          <Typography>{props.username}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ParticipantList = (props: IParticipantList) => {
  const { t } = useTranslation();
  const { roomRef, socketRef, currentUser } = useContext(RoomContext);
  const [numOfParticipants, setNumOfParticipants] = useState(1);
  const [userList, setUserList] = useState<Record<string, ICachedUser>>(null);
  const [isHostPanelCollapsed, toggleHostPanelCollapsed] = useState(false);
  const [isViewerPanelCollapsed, toggleViewerPanelCollapsed] = useState(false);
  const [isInviteModalVisible, toggleInviteModalView] = useState(false);

  const establishedSocketEventsRef = useRef(false);

  useEffect(() => {
    setUserList(roomRef.current?.users);
    setNumOfParticipants(roomRef.current?.userCount);
  }, [currentUser]);

  useEffect(() => {
    const socket = socketRef.current;

    if (!establishedSocketEventsRef.current) {
      socket.on('userJoined', async (data) => {
        roomRef.current.users[data.socketId] = data.user;
        roomRef.current.userCount++;
        setNumOfParticipants(roomRef.current.userCount);
        setUserList(roomRef.current.users);
      });

      socket.on('userLeft', async (data) => {
        delete roomRef.current.users[data.socketId];
        roomRef.current.userCount--;
        if (data.newHostSocketId.length > 0) {
          roomRef.current.hostSocketId = data.newHostSocketId;
        }
        setNumOfParticipants(roomRef.current.userCount);
        setUserList(roomRef.current.users);
      });

      establishedSocketEventsRef.current = true;
    }

    // clean-up event listeners
    return () => {
      socket.off('userJoined');
      socket.off('userLeft');
      establishedSocketEventsRef.current = false;
    };
  }, []);

  return (
    <Slide direction="up" in={props.isVisible} mountOnEnter unmountOnExit>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: THEME.PRIMARY,
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" p={2}>
            <Typography fontWeight="bold">Participants - {numOfParticipants}</Typography>
            <div>
              <IconButton onClick={() => toggleInviteModalView(true)} size="medium">
                <PersonAdd style={{ color: THEME.TEXT }} />
              </IconButton>
              <InviteFriendsModal isVisible={isInviteModalVisible} toggle={toggleInviteModalView} />
              <IconButton onClick={() => props.toggle(false)} size="medium">
                <Close style={{ color: THEME.TEXT }} />
              </IconButton>
            </div>
          </Stack>
          <div style={{ overflow: 'auto', flexGrow: 1, padding: '1rem' }}>
            <Divider sx={{ mb: 2 }} />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" variant="outlined">
                    <Search sx={{ color: THEME.IMPORTANT_TEXT }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                'input': {
                  color: THEME.IMPORTANT_TEXT,
                  background: '#272C38',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#272C38',
                },
              }}
              placeholder="Filter"
              autoComplete="off"
              fullWidth
            />
            <Divider sx={{ pt: 2 }} />
            <div>
              <CollapsiblePanel onClick={() => toggleHostPanelCollapsed(!isHostPanelCollapsed)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1rem',
                  }}
                >
                  <StyledImg alt="Host Icon" src={CROWN_ICON_PNG} />
                  <Typography fontWeight="bold">Hosts</Typography>
                </div>
                {isHostPanelCollapsed ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
              </CollapsiblePanel>
              <Collapse in={!isHostPanelCollapsed} unmountOnExit>
                {userList &&
                  Object.entries(userList).map(
                    ([key, value]) =>
                      key === roomRef.current?.hostSocketId && (
                        <Participant
                          key={key}
                          avatar={value.avatar}
                          accent_color={value.accent_color}
                          username={
                            value.username === roomRef.current?.users[currentUser].username
                              ? `${value.username} (You)`
                              : value.username
                          }
                        />
                      ),
                  )}
              </Collapse>
            </div>
            <Divider />
            <div>
              <CollapsiblePanel onClick={() => toggleViewerPanelCollapsed(!isViewerPanelCollapsed)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1rem',
                  }}
                >
                  <StyledImg alt="Host Icon" src={GROUP_ICON_PNG} />
                  <Typography fontWeight="bold">Viewers</Typography>
                </div>
                {isViewerPanelCollapsed ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
              </CollapsiblePanel>
              <Collapse in={!isViewerPanelCollapsed} unmountOnExit>
                {userList &&
                  Object.entries(userList).map(
                    ([key, value]) =>
                      key !== roomRef.current?.hostSocketId && (
                        <Participant
                          key={key}
                          avatar={value.avatar}
                          accent_color={value.accent_color}
                          username={
                            value.username === roomRef.current?.users[currentUser].username
                              ? `${value.username} (You)`
                              : value.username
                          }
                        />
                      ),
                  )}
              </Collapse>
            </div>
            <Divider />
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default ParticipantList;
