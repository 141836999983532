import { hexToRGBA } from 'utils/commonUtils';

//export const PRIMARY_THEME_COLOR = "#15181b";
// export const SECONDARY_THEME_COLOR = "rgb(35, 188, 234)";

// export const PRIMARY_THEME_COLOR = "rgb(55 148 203)";
// export const PRIMARY_THEME_COLOR_OLD = "rgb(190, 47, 117)";

const THEME_COLORS = {
  /**
   * Use this color as the main background for your website. This will set the dark theme and create a strong foundation for your design.
   */
  PRIMARY: '#18181b',
  /**
   * Utilize for secondary backgrounds, borders, and dividers. Black can also be used for less important text, captions, or less emphasized UI elements.
   */
  SECONDARY: '#272C38',
  /**
   *
   */
  TERTIARY: '',
  /**
   * Use this color as the primary accent color for call-to-action buttons, links, and other interactive elements.
   * This will provide a strong contrast against the dark background and draw attention to important elements.
   */
  ACCENT: '#066AB7', // "#F67E02", //"#059203", //#FCA311",
  /**
   * Use light gray for the primary text color on your dark background. This will ensure good readability and contrast.
   * You can also use it for headings, subheadings, and other UI elements that need to stand out without being as attention-grabbing as the vibrant yellow.
   */
  TEXT: '#E5E5E5',
  /**
   * Utilize **sparingly** for elements that need maximum emphasis, such as logos, selected navigation items, or highlights on important information.
   */
  IMPORTANT_TEXT: '#FFFFFF',
};
THEME_COLORS.TERTIARY = hexToRGBA(THEME_COLORS.SECONDARY, -1);
export const THEME = THEME_COLORS;

// linear-gradient(to bottom right, #000000 0%, #0000ff 100%) blue gradient
// linear-gradient(to bottom right, #003300 0%, #00cc99 100%) Green gradient

export const SHIPPING_COST = 2;

export const EMPTY_PRODUCT_IMG_URL = 'https://source.unsplash.com/random';
export const EMPTY_SHOP_IMG_URL = 'https://source.unsplash.com/random';

export const Key = {
  ENTER: 13,
  LEFT_SHIFT: 16,
  LEFT_ARROW: 37,
};

/**
 * @description The type of premium subscriptions
 * @returns The value associated with the type
 */
export enum PremiumTypes {
  NONE,
  REGULAR,
}

/**
 * @description The flags a given account can have
 * @note These flags are setup in order of least privilage
 * @return The value associated with the flag in order of declaration (0 to n)
 */
export enum AccountFlags {
  STAFF,
  PARTNER,
  VERIFIED,
  WHITEHAT,
  EARLY_SUPPORTER,
  SUPPORT_AGENT,
  USER,
  BANNED,
}

export const VALID_USERNAME_REGEX = /^[a-zA-Z0-9]+([_\s-]?[a-zA-Z0-9])*$/;

export const VALID_EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

export const LOCAL_STORAGE_KEYS = Object.freeze({
  languagePreference: 'localePref',
});

export const ROOM_URL_PATH = '/rooms/';

export const ELLIPSIS = '...';
