import { useState, useRef, useContext } from 'react';
import {
  Typography,
  Stack,
  Button,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Box,
} from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { ContentCopyOutlined, Check, Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';

import { THEME } from 'utils/constants';
import { StyledTextField, PrimaryButton, SecondaryButton, DisabledTab } from 'style/common';
import { GlobalContext } from 'App';
import { RoomContext } from 'views/Room/Room';

import { IFormData } from './types';

export enum PrivacyMode {
  Unlisted,
  Private,
  Public,
}

const MaterialUISwitch = styled(Switch)({
  'width': 102,
  'height': 34,
  'padding': 8,
  '& .MuiSwitch-switchBase': {
    'margin': 1,
    'padding': 0,
    'transform': 'translateX(5px)',
    '&.Mui-checked': {
      'color': THEME.IMPORTANT_TEXT,
      'transform': 'translateX(62px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          THEME.IMPORTANT_TEXT,
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: THEME.SECONDARY,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    'backgroundColor': THEME.ACCENT,
    'width': 32,
    'height': 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        THEME.IMPORTANT_TEXT,
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: THEME.SECONDARY,
    borderRadius: 20 / 2,
  },
});

const Details = () => {
  const { userProfileData } = useContext(GlobalContext);
  const { currentUser, roomRef } = useContext(RoomContext);
  const [roomAvatarBrightness, setRoomAvatarBrightness] = useState(1);

  const [isTitleInvalid, setIsTitleInvalid] = useState(false);
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false);
  const [isTagInvalid, setIsTagInvalid] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [tagError, setTagError] = useState('Only a maximum of 5 tags can be added');

  const formData = useRef<IFormData>({
    title: "username's Room",
    description: '',
    tags: [],
    privacy: PrivacyMode.Unlisted,
  });

  return (
    <>
      <div style={{ position: 'relative' }}>
        <img
          width="100%"
          height="140"
          src="/img/pattern.jpg"
          alt="Room Banner"
          style={{
            cursor: 'pointer',
            filter: `brightness(${roomAvatarBrightness})`,
          }}
          onMouseEnter={() => setRoomAvatarBrightness(0.6)}
          onMouseLeave={() => setRoomAvatarBrightness(1)}
        />
        <Typography
          variant="subtitle2"
          color={THEME.IMPORTANT_TEXT}
          fontWeight="bold"
          style={{
            position: 'absolute',
            textAlign: 'center',
            pointerEvents: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            visibility: roomAvatarBrightness === 1 ? 'hidden' : 'visible',
          }}
        >
          EDIT BANNER
        </Typography>
      </div>
      <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={2} sx={{ overflow: 'auto' }}>
        <Stack spacing={2} width="100%">
          <Stack flexDirection="column">
            <Typography fontWeight="bold" pr={10}>
              {isTitleInvalid ? (
                <span style={{ color: red[400] }}>
                  Title - <em style={{ fontSize: '10px' }}>{titleError}</em>
                </span>
              ) : (
                <>Title</>
              )}
            </Typography>
            <StyledTextField
              autoComplete="off"
              InputProps={{ inputProps: { maxLength: 140 } }}
              onChange={(event) => (formData.current.title = event.target.value)}
              variant="outlined"
              margin="normal"
              placeholder="Example: One Piece Marathon >.<"
              fullWidth
              required
            />
          </Stack>
          <Stack flexDirection="column">
            <Typography fontWeight="bold" pr={3}>
              {isDescriptionInvalid ? (
                <span style={{ color: red[400] }}>
                  Description - <em style={{ fontSize: '10px' }}>{descriptionError}</em>
                </span>
              ) : (
                <>Description</>
              )}
            </Typography>
            <StyledTextField
              autoComplete="off"
              InputProps={{ inputProps: { maxLength: 150 } }}
              onChange={(event) => (formData.current.description = event.target.value)}
              variant="outlined"
              margin="normal"
              placeholder="Example: This room is intented for watching One Piece only, feel free to join nakama!"
              fullWidth
              multiline
              minRows={2}
              maxRows={2}
              required
            />
          </Stack>
          <Stack flexDirection="column">
            <Typography fontWeight="bold" pr={10}>
              {isTagInvalid ? (
                <span style={{ color: red[400] }}>
                  Tags - <em style={{ fontSize: '10px' }}>{tagError}</em>
                </span>
              ) : (
                <>Tags</>
              )}
            </Typography>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              fullWidth
              onChange={(event, newValue) => {
                formData.current.tags = newValue;
              }}
              renderInput={(params) => {
                params.InputProps.endAdornment = null;
                return (
                  <StyledTextField
                    {...params}
                    autoComplete="off"
                    variant="outlined"
                    margin="normal"
                    onKeyDown={(event) => {
                      if (formData.current.tags.length >= 5) {
                        event.preventDefault();
                      }
                    }}
                    fullWidth
                    required
                  />
                );
              }}
            />
          </Stack>
        </Stack>
        <Stack width="100%" alignItems="center">
          <Typography fontWeight="bold">Visibility</Typography>
          <MaterialUISwitch sx={{ mt: 1 }} defaultChecked={false} />
        </Stack>
      </Stack>
      <Button color="error" variant="outlined" sx={{ color: THEME.IMPORTANT_TEXT, borderRadius: 16 }}>
        Delete Room
      </Button>
    </>
  );
};

export default Details;
