import { useEffect } from 'react';
import { getCookieValue } from 'utils/commonUtils';
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom';
import io from 'socket.io-client';

const useSocket = (socketRef, setLoaded, roomRef) => {

    const history = useHistory<RouteComponentProps>();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const socket = io(process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:8080', {
          query: { roomId: id },
          transports: ['websocket'],
          withCredentials: true,
          auth: {
            token: getCookieValue('token'),
          },
        });
        socketRef.current = socket;
        socketRef.current.on('connection', async () => {
          setLoaded(true);
          socketRef.current.emit('joinRoom');
        });
        socketRef.current.on('usernameUpdated', async (data) => {
          if (roomRef.current) {
            roomRef.current.users[data.for].username = data.username;
          }
        });
        socketRef.current.on('redirect', async (url) => history.push(url));
    
        return () => {
          socket.disconnect();
        };
      }, [id, history]);
};

export default useSocket;
