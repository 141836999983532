import RoomBannerImg from 'assets/static/images/room-banner.jpg';
import { Language } from './commonTypes';
import { ELLIPSIS } from './constants';

/**
 * Function to ensure the string is a valid Language
 */
export const isValidLangauge = (value: string): value is Language => ['en', 'fr'].indexOf(value) !== -1;

/**
 * Function that converts a hexadecimal color code to an RGBA one
 * @param hex - The hex string to convert
 * @param brightnessMultiplier - The multiplier to apply to the converted hex color (e.g 1.2 = 20% brighter)
 * @param alpha - The alpha value of the returned RGBA value (e.g defaults to 1 = 100%)
 */
export const hexToRGBA = (hex: string, brightnessMultiplier = 0, alpha = 1) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return (
      `rgba(` +
      [
        ((c >> 16) & 255) + 10 * brightnessMultiplier,
        ((c >> 8) & 255) + 10 * brightnessMultiplier,
        (c & 255) + 10 * brightnessMultiplier,
      ].join(',') +
      `,${alpha})`
    );
  }
  throw new Error('Bad Hex');
};

/**
 * Function checks if a string value is any of the ones in the list provided
 */
export const isAnyOf = (value: string, listOfStrings: string[]) => {
  for (let i = 0; i < listOfStrings.length; i++) {
    if (value === listOfStrings[i]) {
      return true;
    }
  }
  return false;
};

/**
 * Function that looks for a cookie key and returns the value if found
 * @note An empty string is returned if the key wasn't found in the cookie
 */
export const getCookieValue = (cookieKey: string) => {
  if (cookieKey) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const [key, value] = cookie.split('=');

      if (key === cookieKey) {
        return decodeURIComponent(value);
      }
    }
  }
  return '';
};

/**
 * Function that returns the reference to the default banner image
 */
export const getDefaultBannerImgUrl = () =>
  process.env.NODE_ENV === 'production'
    ? 'https://cdn.synther.io/embed/default/room-banner.jpg'
    : (RoomBannerImg as string);

/**
 * This function will shorten a piece of text and append ellipsis at the end of the string.
 * @param text The text to truncate and append '...' at the end to.
 * @param characterLimit The character threshold in which the truncation will occur. (>=)
 */
export const truncateText = (text: string, characterLimit = 500): string => {
  if (text?.length >= characterLimit) {
    return `${text.substring(0, characterLimit)}${ELLIPSIS}`;
  }
  return text;
};
