import { useRef, useContext, useEffect, useState, useCallback } from 'react';

import { Key } from 'utils/constants';

import { RoomContext } from '../Room';
import { GlobalContext } from 'App';

import useSocket from './SocketEvents';

import ReactPlayer from 'react-player/lazy';
import { VideoPlayerWrapper } from '../style';
import Browse from 'components/BrowseModal';

export enum MediaTypeEnum {
  YouTube = 'youtube',
  Twitch = 'twitch',
  Spotify = 'spotify',
  SoundCloud = 'soundcloud',
  WebTorrent = 'webtorrent',
  ScreenShare = 'screenshare',
}

const VideoPlayer = ({ isMuted, isBrowseMediaViewVisible, toggleOverlayMenu, toggleBrowseMediaView }) => {
  const { isLoggedIn } = useContext(GlobalContext);
  const { socketRef } = useContext(RoomContext);

  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const hideOverlayTimeout = useRef<NodeJS.Timeout>(null);

  // player state
  const [playerState, setPlayerState] = useState({
    source: '',
    playing: true,
    tracks: [],
    currentMedia: '',
    videoValue: '',
    time: 0,
    host: false,
    hostID: '',
    playBackRate: 1,
  });
  const playerRef = useRef<any>();
  const prevTime = useRef<any>();
  const supressEvents = useRef<any>();

  useSocket(playerState, setPlayerState, playerRef, supressEvents);

  // playerState hook
  useEffect(() => {
    if (playerState.host) prevTime.current = playerState.time; // update prevTime to handle paused seeking
    if (supressEvents.current) {
      // supress events while viewer is getting host state (viewer only)
      setTimeout(() => {
        supressEvents.current = false;
      }, 750); // 750 seems to work in most cases...TODO: overlay unclickable view over the player for this duration
    }
  }, [playerState]);

  // Runs once when player ready
  const onPlayerReady = () => {
    if (!playerState.host) socketRef.current.emit('getHostPlayerState'); // sync with host again to compensate for loading latency (viewer only)
    setInterval(() => {
      // seek timer
      if (
        playerRef.current &&
        !playerRef.current.player.isPlaying &&
        prevTime.current !== playerRef.current.getCurrentTime()
      ) {
        prevTime.current = playerRef.current.getCurrentTime();
        helper.onSeek();
      }
    }, 750);
  };

  // playerState hook
  useEffect(() => {
    console.log('works');
  }, [playerRef]);

  const updatePlayerState = useCallback(
    (newPlayerState) => {
      // helper send call to modify playerState and disallows unauthorized events
      if (playerState.host) {
        console.log(newPlayerState, 'host emit');
        socketRef.current.emit('playerEvent', newPlayerState);
        setPlayerState((prevState) => ({ ...prevState, ...newPlayerState }));
      }
      if (!playerState.host && supressEvents.current) {
        // keep this for debugging
        if (process.env.NODE_ENV === 'development') console.log('event suppressed');
      } else if (!playerState.host) {
        // capture unauthorized state changes and resync with host
        console.log('viewer state change');
        setPlayerState((prevState) => ({ ...prevState, ...newPlayerState })); // prevent viewer player state from de-syncing from its ref
        socketRef.current.emit('getHostPlayerState');
      }
    },
    [playerState],
  );

  const helper = {
    onPlaybackStateChange: (playing) =>
      updatePlayerState({
        playing: playing,
        time: playerRef.current.getCurrentTime(),
      }),
    onPlaybackRateChange: () =>
      updatePlayerState({
        playBackRate: playerRef.current.getInternalPlayer().getPlaybackRate(),
        time: playerRef.current.getCurrentTime(),
      }),
    onSeek: () =>
      updatePlayerState({
        time: playerRef.current.getCurrentTime(),
      }),
  };

  const onChangeVideo = (e: any) => {
    if (e.keyCode === Key.ENTER && playerState.videoValue !== '') {
      playerRef.current.seekTo(0, 'seconds');
      updatePlayerState({
        source: playerState.videoValue,
        time: 0,
      });
    }
  };
  const onSwitchMedia = (newMediaType: MediaTypeEnum) => {
    toggleBrowseMediaView(true);
    updatePlayerState({
      currentMedia: newMediaType,
    });
  };
  const onURLChange = (e: any) => {
    const value = e.target.value;
    setPlayerState({ ...playerState, videoValue: value });
  };

  return (
    <VideoPlayerWrapper
      flexGrow={1}
      onMouseEnter={() => {
        clearTimeout(hideOverlayTimeout.current);
        toggleOverlayMenu(true);
        hideOverlayTimeout.current = setTimeout(() => toggleOverlayMenu(false), 2000);
      }}
      onMouseOut={() => toggleOverlayMenu(false)}
      style={{
        ...(isMobileDevice ? { height: 'auto', minHeight: '50%' } : { flex: 1, height: '100%' }),
      }}
    >
      <Browse
        currentMedia={playerState.currentMedia}
        isVisible={isBrowseMediaViewVisible}
        toggle={toggleBrowseMediaView}
        videoValue={playerState.videoValue}
        onURLChangeCbk={onURLChange}
        onChangeVideoCbk={onChangeVideo}
        onSwitchMediaCbk={onSwitchMedia}
      />

      {!isLoggedIn && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(1rem)',
            background: 'rgba(0,0,0,0.85)',
          }}
        />
      )}
      <ReactPlayer
        ref={playerRef}
        playing={playerState.playing}
        controls
        url={playerState.source}
        muted={isMuted}
        width="100%"
        height="auto"
        style={{ aspectRatio: '16/9' }}
        onPlay={() => helper.onPlaybackStateChange(true)}
        onPause={() => helper.onPlaybackStateChange(false)}
        onPlaybackRateChange={() => helper.onPlaybackRateChange()}
        onReady={onPlayerReady}
        onStart={() => {
          console.log('start');
        }} // playerRef doest update initial state without this
        config={{
          file: {
            tracks: playerState.tracks,
          },
        }}
      />
    </VideoPlayerWrapper>
  );
};

export default VideoPlayer;
