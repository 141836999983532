import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, FormControl, Container, Stack, Typography, Grid } from '@mui/material';
import { Twitter, Instagram, Facebook, YouTube } from '@mui/icons-material';
import Select from '@mui/material/Select';

import { ReactComponent as USFlagSVG } from 'assets/svg/flags/us.svg';
import { ReactComponent as FRFlagSVG } from 'assets/svg/flags/fr.svg';
import { ReactComponent as DEFlagSVG } from 'assets/svg/flags/de.svg';
import { Language } from 'utils/commonTypes';
import { THEME, LOCAL_STORAGE_KEYS } from 'utils/constants';
import { NavItem } from 'style/common';

import { FooterWrapper } from './style';
import { isValidLangauge } from 'utils/commonUtils';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<Language>('en');

  const setLanguageFunc = useCallback(
    (newLanguage: Language) => {
      if (newLanguage && isValidLangauge(newLanguage)) {
        setLanguage(newLanguage);
        localStorage.setItem(LOCAL_STORAGE_KEYS.languagePreference, newLanguage);
        i18n.changeLanguage(newLanguage);
      }
    },
    [i18n],
  );

  useEffect(
    () => setLanguageFunc(localStorage.getItem(LOCAL_STORAGE_KEYS.languagePreference) as Language),
    [setLanguageFunc],
  );

  return (
    <FooterWrapper>
      <Container maxWidth="md">
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={20}
          pb={10}
          spacing={20}
        >
          <Grid container spacing={12} justifyContent="center">
            <Grid item md={4} lg={3}>
              <Stack>
                <Typography fontWeight="bold" variant="h5" sx={{ color: THEME.IMPORTANT_TEXT }}>
                  {t('footer.product')}
                </Typography>
                <NavItem style={{ padding: 0 }} to="/ultra">
                  {t('ultra')}
                </NavItem>
                <NavItem style={{ padding: 0 }} to="/login">
                  {t('login')}
                </NavItem>
                <NavItem style={{ padding: 0 }} to="/register">
                  {t('sign_up')}
                </NavItem>
              </Stack>
            </Grid>
            <Grid item md={4} lg={3}>
              <Stack>
                <Typography fontWeight="bold" variant="h5" sx={{ color: THEME.IMPORTANT_TEXT }}>
                  {t('footer.resources')}
                </Typography>
                {/* <NavItem style={{ padding: 0 }} to="/#!">
                  Brand
                </NavItem> */}
                <NavItem style={{ padding: 0 }} to="/faq">
                  {t('faq')}
                </NavItem>
                <NavItem style={{ padding: 0 }} to="/support">
                  {t('support')}
                </NavItem>
              </Stack>
            </Grid>
            <Grid item md={4} lg={3}>
              <Stack>
                <Typography fontWeight="bold" variant="h5" sx={{ color: THEME.IMPORTANT_TEXT }}>
                  {t('footer.policies')}
                </Typography>
                <NavItem style={{ padding: 0 }} to="/terms">
                  {t('terms_of_use')}
                </NavItem>
                <NavItem style={{ padding: 0 }} to="/privacy">
                  {t('privacy_policy')}
                </NavItem>
              </Stack>
            </Grid>
            <Grid item md={4} lg={3}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    onChange={(e) => setLanguageFunc(e.target.value as Language)}
                    sx={{
                      'color': THEME.IMPORTANT_TEXT,
                      '& .MuiSelect-icon': {
                        color: THEME.TEXT,
                      },
                      '& .MuiSelect-root': {
                        'border': 'none',
                        '&:hover': {
                          border: 'none',
                          boxShadow: 'none',
                        },
                        '&.Mui-focused': {
                          border: 'none',
                          boxShadow: 'none',
                        },
                      },
                    }}
                  >
                    <MenuItem value={'en'}>
                      <USFlagSVG width="15" style={{ marginRight: '8px' }} />
                      English
                    </MenuItem>
                    <MenuItem value={'fr'}>
                      <FRFlagSVG width="15" style={{ marginRight: '8px' }} />
                      Français
                    </MenuItem>
                    <MenuItem value={'de'}>
                      <DEFlagSVG width="15" style={{ marginRight: '8px' }} />
                      Deutsch
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Stack flexDirection="row" alignItems="center" mt={3}>
                <Twitter sx={{ cursor: 'pointer', mr: 3 }} />
                <Instagram sx={{ cursor: 'pointer', mr: 3 }} />
                <Facebook sx={{ cursor: 'pointer', mr: 3 }} />
                <YouTube sx={{ cursor: 'pointer', mr: 3 }} />
              </Stack>
            </Grid>
          </Grid>
          <Typography align="center">© {new Date().getFullYear()} Voidbyte Inc. All rights reserved.</Typography>
        </Stack>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
