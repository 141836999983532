import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar, Typography, Box, Grid, IconButton, Modal, Stack } from '@mui/material';
import { AddCircle, SettingsSharp, PeopleAlt, Tv, Start, PlaylistPlay, MeetingRoom } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import { PrimaryButton, SecondaryButton, StyledTextField } from 'style/common';
import Login from 'views/Login';
import ProfileMenu from 'components/common/ProfileMenu';
import RoomSettings from 'components/common/RoomSettings';
import { ReactComponent as ExitRoomIcon } from 'assets/svg/room/exit_room_icon1.svg';
import { THEME, Key, VALID_USERNAME_REGEX } from 'utils/constants';
import { GlobalContext } from 'App';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import { RoomContext } from 'views/Room/Room';

import ParticipantList from './ParticipantList';
import EmojiRandomizer from './EmojiRandomizer';
import IChatbox, { MessageType, IMessageData, IClientMessagePayload, IServerMessagePayload } from './types';
import {
  ChatboxWrapper,
  ChatboxContainer,
  ChatboxHeader,
  GuestLogin,
  TermsContainer,
  LoginModalWrapperStyle,
} from './style';

const Chatbox = (props: IChatbox) => {
  const { t } = useTranslation();
  const { socketRef, roomRef } = useContext(RoomContext);
  const { isLoggedIn, userProfileData, setUserProfileData } = useContext(GlobalContext);
  const history = useHistory();
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [shiftHeld, setShiftHeld] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<IMessageData<'server' | 'client'>[]>([]);

  const [isRoomSettingsVisible, toggleRoomSettingsView] = useState(false);
  const [isParticipantListViewVisible, toggleParticipantListView] = useState(false);
  const [showToggleChatboxBtn, setShowToggleChatboxBtn] = useState(false);
  const [isChatboxVisible, toggleChatboxVisibility] = useState(true);

  // login textfield
  const [username, setUsername] = useState('');

  // login modal
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // confirmation modal
  const [shouldConfirmLeaveAction, setShouldConfirmLeaveAction] = useState(false);

  const chatboxRef = useRef<any>();
  const establishedSocketEventsRef = useRef(false);

  const addMessage = (type: MessageType, payload: IClientMessagePayload | IServerMessagePayload) => {
    setMessages((messages) => [
      ...messages,
      {
        type,
        payload,
      },
    ]);
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const socket = socketRef.current;

    if (!establishedSocketEventsRef.current) {
      socket.on('clientMessage', async (data) => addMessage('client', data));
      socket.on('serverMessage', async (data) => addMessage('server', data));

      establishedSocketEventsRef.current = true;
    }

    // clean-up event listeners
    return () => {
      socket.off('clientMessage');
      socket.off('serverMessage');
      establishedSocketEventsRef.current = false;
    };
  }, [roomRef, socketRef]);

  const onMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const onEnter = (e: any) => {
    if (!shiftHeld) {
      if (e.keyCode === Key.LEFT_SHIFT) {
        setShiftHeld(true);
      } else if (e.keyCode === Key.ENTER) {
        e.preventDefault();
        socketRef.current.emit('sendMessage', message);
        setMessage('');
      }
    }
  };

  const releaseKey = (e: any) => {
    if (e.keyCode === Key.LEFT_SHIFT) setShiftHeld(false);
  };

  return (
    <ChatboxWrapper
      style={{
        width: isChatboxVisible ? '100%' : '0',
        maxWidth: isMobileDevice ? '100%' : '22%',
        maxHeight: isMobileDevice ? '50%' : '100%',
      }}
    >
      {!isMobileDevice && !isChatboxVisible && props.isOverlayMenuVisible && (
        <IconButton
          sx={{ position: 'absolute', top: '50%', right: '1rem', bgcolor: 'rgba(255,255,255,0.1)', zIndex: '99999' }}
          onClick={() => toggleChatboxVisibility(true)}
          onMouseEnter={() => props.setOverlayMenuVisible(true)}
        >
          <Start sx={{ color: THEME.TEXT, transform: 'scaleX(-1)' }} />
        </IconButton>
      )}
      <ChatboxContainer>
        {isLoggedIn ? (
          <Box display="flex" flexDirection="column" flexWrap="nowrap" style={{ height: '100%', position: 'relative' }}>
            <ParticipantList isVisible={isParticipantListViewVisible} toggle={toggleParticipantListView} />
            <ChatboxHeader>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
              >
                <Grid item>
                  <IconButton
                    onClick={() => setShouldConfirmLeaveAction(true)}
                    size="medium"
                    color="error"
                    sx={{ bgcolor: THEME.SECONDARY, borderRadius: '2rem' }}
                  >
                    {/* <ExitRoomIcon width="20px" height="20px" /> */}
                    <MeetingRoom sx={{ color: red[900] }} />
                  </IconButton>
                  <ConfirmationModal
                    isVisible={shouldConfirmLeaveAction}
                    toggle={setShouldConfirmLeaveAction}
                    title="Are you sure you want to leave?"
                    onConfirmCbk={() => history.push('/')}
                    onCancelCbk={() => setShouldConfirmLeaveAction(false)}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => props.toggleBrowseMediaView(true)}
                    size="medium"
                    color="primary"
                    sx={{ bgcolor: THEME.SECONDARY, borderRadius: '2rem' }}
                  >
                    <Tv style={{ color: THEME.ACCENT }} />
                  </IconButton>
                </Grid>
                {/* <Grid item>
                  <IconButton onClick={() => toggleParticipantListView(true)} size="medium">
                    <PlaylistPlay style={{ color: THEME.TEXT }} />
                  </IconButton>
                </Grid> */}
                <Grid item>
                  <IconButton onClick={() => toggleParticipantListView(true)} size="medium">
                    <PeopleAlt style={{ color: THEME.TEXT }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => toggleRoomSettingsView(true)} size="medium">
                    <SettingsSharp style={{ color: THEME.TEXT }} />
                  </IconButton>
                  <RoomSettings isVisible={isRoomSettingsVisible} toggle={toggleRoomSettingsView} />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={(e: any) => {
                      if (userProfileData.is_guest) {
                        setIsLoginModalOpen(true);
                      } else {
                        setAnchorEl(e.currentTarget);
                      }
                    }}
                    size="medium"
                  >
                    <Avatar
                      alt="Account Avatar"
                      src={userProfileData?.avatar}
                      sx={{
                        width: '25px',
                        height: '25px',
                        backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ChatboxHeader>
            {!isMobileDevice && showToggleChatboxBtn && (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '-20px',
                  bgcolor: 'rgba(255,255,255,0.1)',
                }}
                onMouseEnter={() => setShowToggleChatboxBtn(true)}
                onMouseLeave={() => setShowToggleChatboxBtn(false)}
                onClick={() => toggleChatboxVisibility(false)}
              >
                <Start sx={{ color: THEME.TEXT }} />
              </IconButton>
            )}
            <Box
              ref={chatboxRef}
              className="custom-scrollbar"
              flexGrow={1}
              style={{
                overflowX: 'hidden',
                padding: '1rem 1rem 1rem 0.3rem',
                scrollBehavior: 'smooth',
              }}
              onMouseOver={() => setShowToggleChatboxBtn(true)}
              onMouseLeave={() => setShowToggleChatboxBtn(false)}
            >
              {messages.map((message, index) => {
                if (message.type === 'server') {
                  const messagePayload = message.payload as IServerMessagePayload;
                  return (
                    <Grid key={'serverMessage' + index} container direction="row" justifyContent="center">
                      <Grid item>
                        <div
                          style={{
                            overflowWrap: 'anywhere',
                            backgroundColor: 'unset',
                          }}
                        >
                          <Typography
                            style={{
                              padding: '0.5rem',
                              paddingTop: '0',
                              color: THEME.IMPORTANT_TEXT,
                            }}
                            align="center"
                          >
                            {t(messagePayload.i18Label, messagePayload.values)}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  );
                } else if (message.type === 'client') {
                  const messagePayload = message.payload as IClientMessagePayload;
                  return (
                    <Grid key={'message' + index} container direction="row" style={{ paddingBottom: '1rem' }}>
                      <Grid item xs={2} pt={2} display="flex" alignItems="start" justifyContent="center">
                        <Avatar
                          alt="Account Avatar"
                          src={userProfileData?.avatar}
                          sx={{
                            width: '25px',
                            height: '25px',
                            backgroundColor: userProfileData?.avatar == null ? userProfileData.accent_color : 'none',
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            overflowWrap: 'anywhere',
                            boxShadow: '0 0 35px rgba(0,0,0,0.25)',
                            backgroundColor: THEME.SECONDARY,
                            borderRadius: '1rem',
                            padding: '1rem',
                          }}
                        >
                          <Typography
                            style={{
                              paddingBottom: '0',
                              color: messagePayload.color,
                            }}
                            fontWeight="bold"
                          >
                            {messagePayload.from}
                          </Typography>
                          <Typography style={{ paddingTop: '0', color: THEME.TEXT }}>
                            {messagePayload.message}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Box>
            <Box>
              <StyledTextField
                id="chat-textfield"
                label="Chat"
                value={message}
                variant="filled"
                onChange={onMessageChange}
                onKeyDown={onEnter}
                onKeyUp={releaseKey}
                placeholder="Send a message"
                InputProps={{
                  sx: {
                    color: THEME.TEXT,
                  },
                  startAdornment: (
                    <>
                      <input id="upload-attachment" multiple type="file" hidden />
                      <label htmlFor="upload-attachment">
                        <IconButton size="small" component="span">
                          <AddCircle fontSize="small" sx={{ color: THEME.TEXT }} />
                        </IconButton>
                      </label>
                    </>
                  ),
                  endAdornment: (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <EmojiRandomizer />
                      <IconButton size="small">
                        <SettingsSharp fontSize="small" sx={{ color: THEME.TEXT }} />
                      </IconButton>
                    </Stack>
                  ),
                }}
                inputProps={{ maxLength: 3000 }}
                sx={{
                  label: {
                    color: THEME.IMPORTANT_TEXT,
                    paddingTop: 0,
                    fontWeight: 'bold',
                  },
                  borderRadius: '1rem 1rem 0 0',
                }}
                maxRows={4}
                multiline
                fullWidth
              />
            </Box>
          </Box>
        ) : (
          <>
            <GuestLogin>
              <Stack display={'flex'} alignContent={'center'} margin={3} spacing={2}>
                <Typography variant="button" fontWeight="bold">
                  {username !== '' && !VALID_USERNAME_REGEX.test(username) ? (
                    <span style={{ color: red[400] }}>
                      Chat as - <em style={{ fontSize: '10px' }}>Your username contains invalid characters</em>
                    </span>
                  ) : (
                    <>Chat as</>
                  )}
                </Typography>
                <StyledTextField
                  variant="outlined"
                  placeholder="Enter nickname"
                  onChange={(event) => setUsername(event.target.value)}
                  error={username !== '' && !VALID_USERNAME_REGEX.test(username)}
                  inputProps={{ maxLength: 20 }}
                  autoComplete="off"
                />
                <PrimaryButton
                  onClick={() => {
                    props.toggleMute(false);
                    setUserProfileData({
                      is_guest: true,
                      username: username,
                      accent_color: '',
                    });
                    socketRef.current.emit('updateUsername', username);
                  }}
                >
                  Continue
                </PrimaryButton>
                <SecondaryButton onClick={() => history.push('/')} style={{ marginLeft: 0 }}>
                  Cancel
                </SecondaryButton>
              </Stack>
            </GuestLogin>
            <TermsContainer>
              <Typography align="center">
                By continuing to use our services, you agree to our{' '}
                <Link to="/terms" target="_blank" style={{ padding: 0, color: THEME.ACCENT }}>
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link to="/privacy" target="_blank" style={{ padding: 0, color: THEME.ACCENT }}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </TermsContainer>
          </>
        )}
        <Modal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          style={{
            backdropFilter: 'blur(5px)',
          }}
        >
          <Box sx={{ ...LoginModalWrapperStyle, ...(isMobileDevice ? { width: '100%' } : {}) }}>
            <Login message="Login" onLoginCbk={() => setIsLoginModalOpen(false)} />
          </Box>
        </Modal>
        <ProfileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </ChatboxContainer>
    </ChatboxWrapper>
  );
};

export default Chatbox;
