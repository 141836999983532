import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  Link,
  MenuItem,
  FormControl,
  Tooltip,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { Visibility, VisibilityOff, LockRounded } from '@mui/icons-material';

import { GlobalContext } from 'App';
import { registerUser } from 'api/auth';
import { THEME, VALID_EMAIL_REGEX, VALID_USERNAME_REGEX } from 'utils/constants';
import { StyledTextField, PrimaryButton } from 'style/common';
import AuthWrapper from 'components/AuthWrapper';
import { FormItem } from 'components/AuthWrapper/style';

import { DOBContainer, StyledInputLabel, StyledSelect, RegisterFooter, UnauthorizedRegisterWrapper } from './style';

import { useTranslation } from 'react-i18next';

const isOlderThan13Years = (date: Date) => {
  const date13YearsAgo = new Date();
  date13YearsAgo.setFullYear(date13YearsAgo.getFullYear() - 13);
  return date < date13YearsAgo;
};

const UnauthorizedRegister = () => {
  const history = useHistory();

  return (
    <UnauthorizedRegisterWrapper>
      <LockRounded sx={{ color: THEME.TEXT }} />
      <Typography
        variant="h4"
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          m: 4,
        }}
        align="center"
      >
        Unauthorized Registeristration
      </Typography>
      <PrimaryButton fullWidth variant="contained" color="primary" onClick={() => history.push('/login')}>
        Back to Login
      </PrimaryButton>
    </UnauthorizedRegisterWrapper>
  );
};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Register = () => {
  const history = useHistory();
  const { setUserProfileData, isFullyLoggedIn } = useContext(GlobalContext);

  if (isFullyLoggedIn) {
    history.push('/app');
  }

  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isDOBInvalid, setIsDOBInvalid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { t } = useTranslation();

  const formData = useRef<{ email: string; username: string; password: string }>({
    email: '',
    username: '',
    password: '',
  });
  const [year, setYear] = useState<number>(null);
  const [month, setMonth] = useState<string>(null);
  const [day, setDay] = useState<number>(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [allowExtraEmails, setAllowExtraEmails] = useState(true);

  const [isUnderAge, setIsUnderAge] = useState(localStorage.getItem('underage') || false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    document.title = 'Synther | Register';
  }, []);

  const registerAccount = async () => {
    // validate parameters
    let isEmailValid = true,
      isUsernameValid = true,
      isPasswordValid = true;

    if (formData.current.email === '') {
      isEmailValid = false;
      setEmailError('Can not be empty.');
    } else if (!VALID_EMAIL_REGEX.test(formData.current.email)) {
      isEmailValid = false;
      setEmailError('Improperly formed address.');
    }

    if (formData.current.username === '') {
      isUsernameValid = false;
      setUsernameError('Can not be empty.');
    } else if (formData.current.username.length < 4) {
      isUsernameValid = false;
      setUsernameError('Must contain at least 4 or more characters.');
    } else if (formData.current.username.length >= 16) {
      isUsernameValid = false;
      setUsernameError('Must contain less than 16 characters.');
    } else if (!VALID_USERNAME_REGEX.test(formData.current.username.trim())) {
      isUsernameValid = false;
      setUsernameError('Can only contain letters, numbers, underscores, hyphens, and spaces.');
    }

    if (formData.current.password === '') {
      isPasswordValid = false;
      setPasswordError('Your password cannot be empty.');
    } else if (formData.current.password.length < 4) {
      isPasswordValid = false;
      setPasswordError('Must contain at least 4 or more characters.');
    } else if (formData.current.password.length > 128) {
      isPasswordValid = false;
      setPasswordError('Your password is too long!');
    }

    // check if DOB is valid
    const date = new Date(month + ' ' + day + ', ' + year);
    if (date.toDateString() === 'Invalid Date') {
      setIsDOBInvalid(true);
    } else if (!isOlderThan13Years(date)) {
      localStorage.setItem('underage', 'true');
      setIsUnderAge(true);
    }

    setIsEmailInvalid(!isEmailValid);
    setIsUsernameInvalid(!isUsernameValid);
    setIsPasswordInvalid(!isPasswordValid);

    if (!isEmailValid || !isUsernameValid || !isPasswordValid) {
      return;
    }

    try {
      const result = await registerUser({
        email: formData.current.email,
        username: formData.current.username,
        password: formData.current.password,
        dob: { month: month, day: day, year: year },
        agreedToTerms: agreedToTerms,
        allowExtraEmails: allowExtraEmails,
      });

      if (result.type === 'error') {
        let isInvalid = 'email' in result.data;
        setIsEmailInvalid(isInvalid);
        setEmailError(isInvalid ? result.data.email : '');

        isInvalid = 'username' in result.data;
        setIsUsernameInvalid(isInvalid);
        setUsernameError(isInvalid ? result.data.username : '');

        isInvalid = 'password' in result.data;
        setIsPasswordInvalid(isInvalid);
        setPasswordError(isInvalid ? result.data.password : '');

        isInvalid = 'dob' in result.data;
        setIsDOBInvalid(isInvalid);
      } else {
        setUserProfileData(result.data);
        window.location.pathname = '/app';
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthWrapper isModal={false}>
      {isUnderAge ? (
        <UnauthorizedRegister />
      ) : (
        <>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
            }}
            align="center"
          >
            {t('create_an_account')}
          </Typography>
          <FormItem>
            <Typography variant="button" fontWeight="bold">
              {isEmailInvalid ? (
                <span style={{ color: red[400] }}>
                  {t('email')} - <em style={{ fontSize: '10px' }}>{emailError}</em>
                </span>
              ) : (
                t('email')
              )}
            </Typography>
            <StyledTextField
              autoComplete="off"
              onChange={(event) => (formData.current.email = event.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoFocus
            />
          </FormItem>
          <FormItem>
            <Typography variant="button" fontWeight="bold">
              {isUsernameInvalid ? (
                <span style={{ color: red[400] }}>
                  {t('username')} - <em style={{ fontSize: '10px' }}>{usernameError}</em>
                </span>
              ) : (
                t('username')
              )}
            </Typography>
            <StyledTextField
              autoComplete="off"
              onChange={(event) => (formData.current.username = event.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
          </FormItem>
          <FormItem>
            <Typography variant="button" fontWeight="bold">
              {isPasswordInvalid ? (
                <span style={{ color: red[400] }}>
                  {t('password')} - <em style={{ fontSize: '10px' }}>{passwordError}</em>
                </span>
              ) : (
                t('password')
              )}
            </Typography>
            <StyledTextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setPasswordVisible((currentPasswordVisible) => !currentPasswordVisible)}
                      size="large"
                    >
                      {isPasswordVisible ? (
                        <Visibility htmlColor={THEME.TEXT} />
                      ) : (
                        <VisibilityOff htmlColor={THEME.IMPORTANT_TEXT} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              onChange={(event) => (formData.current.password = event.target.value)}
              variant="outlined"
              margin="normal"
              type={isPasswordVisible ? 'text' : 'password'}
              fullWidth
              required
            />
          </FormItem>
          <FormItem>
            <Typography variant="button" fontWeight="bold">
              {isDOBInvalid ? (
                <span style={{ color: red[400] }}>
                  {t('dob')} - <em style={{ fontSize: '10px' }}>Invalid</em>
                </span>
              ) : (
                t('dob')
              )}
            </Typography>
            <FormItem>
              <DOBContainer>
                <Box sx={{ minWidth: '35%' }}>
                  <FormControl fullWidth>
                    <StyledInputLabel id="month-input-label">Month</StyledInputLabel>
                    <StyledSelect
                      labelId="month-input-label"
                      id="month-selection"
                      value={month}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      onChange={(e: any) => setMonth(e.target.value)}
                      // displayEmpty
                      // renderValue={(s) => (s ? s : <>Month</>)}
                    >
                      {MONTHS.map((month) => (
                        <MenuItem key={month} value={month}>
                          {month}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: '30%' }}>
                  <FormControl fullWidth>
                    <StyledInputLabel id="day-input-label">Day</StyledInputLabel>
                    <StyledSelect
                      labelId="day-input-label"
                      id="day-selection"
                      value={day}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      onChange={(e: any) => setDay(e.target.value)}
                      // displayEmpty
                      // renderValue={(s) => (s ? s : <>Day</>)}
                    >
                      {Array(31)
                        .fill(0)
                        .map((_, index) => index + 1)
                        .map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: '30%' }}>
                  <FormControl fullWidth>
                    <StyledInputLabel id="year-input-label">Year</StyledInputLabel>
                    <StyledSelect
                      labelId="year-input-label"
                      id="year-selection"
                      value={year}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      onChange={(e: any) => setYear(e.target.value)}
                      label="Year"
                    >
                      {Array(100)
                        .fill(0)
                        .map((_, index) => new Date().getFullYear() - index)
                        .map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
              </DOBContainer>
            </FormItem>
          </FormItem>
          <FormItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: THEME.TEXT }}
                    onChange={(e: any) => setAgreedToTerms(e.target.checked)}
                    required
                  />
                }
                label={
                  <>
                    {t('read_and_agreed')}{' '}
                    <Link
                      onClick={() => window.open(`/terms`, '_blank')}
                      style={{ cursor: 'pointer', color: THEME.ACCENT }}
                      underline="hover"
                    >
                      {t('terms_of_service')}
                    </Link>{' '}
                    and{' '}
                    <Link
                      onClick={() => window.open('/privacy', '_blank')}
                      style={{ cursor: 'pointer', color: THEME.ACCENT }}
                      underline="hover"
                    >
                      {t('privacy_policy')}
                    </Link>
                    .
                  </>
                }
              />
            </FormGroup>
          </FormItem>
          <FormItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ color: THEME.TEXT }}
                    onChange={(e: any) => setAllowExtraEmails(e.target.checked)}
                  />
                }
                label="I want to receive inspiration, marketing promotions, and updates via email."
              />
            </FormGroup>
          </FormItem>
          <FormItem>
            <Tooltip
              title={
                agreedToTerms ? '' : 'You must agree to our terms of service and privacy policy in order to continue.'
              }
            >
              <span>
                <PrimaryButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={registerAccount}
                  disabled={!agreedToTerms}
                >
                  {t('continue')}
                </PrimaryButton>
              </span>
            </Tooltip>
          </FormItem>
          <RegisterFooter>
            <Typography>
              {t('already_have_account')}{' '}
              <Link
                onClick={() => history.push('/login')}
                style={{ cursor: 'pointer', color: THEME.ACCENT }}
                underline="hover"
              >
                {t('login')}
              </Link>
            </Typography>
          </RegisterFooter>
        </>
      )}
    </AuthWrapper>
  );
};

export default Register;
