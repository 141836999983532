import { useState, useEffect, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Tabs, Tab, Typography, Box, Avatar, Button, Stack } from '@mui/material';

import { GlobalContext } from 'App';
import { THEME } from 'utils/constants';
import { SecondaryButton } from 'style/common';

import { useTranslation } from 'react-i18next';

import {
  SettingsWrapper,
  SettingsContainer,
  ProfilePreview,
  ProfileBannerPreview,
  ProfileAvatarPreview,
  ProfileInfo,
  ProfileParameter,
} from './style';

const StyledProfilePreview = styled(ProfilePreview)`
  padding: 1rem;
`;

const TabPanel = (props: { children?: JSX.Element; index: number; value: number }) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const SettingsPanel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoggedIn, userProfileData } = useContext(GlobalContext);
  const [value, setValue] = useState(0);
  const [brightnessAvatar, setBrightnessAvatar] = useState(1);

  useEffect(() => {
    document.title = 'Synther | Settings';
  }, []);

  const handleAvatarHover = (brightness: number) => {
    setBrightnessAvatar(brightness);
  };

  if (!isLoggedIn) {
    alert('yes');
    return <Redirect to="/" />;
  }

  return (
    <SettingsWrapper>
      <SettingsContainer>
        <Typography variant="h3" fontWeight="bold">
          {t('settings')}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: THEME.ACCENT }}>
            <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
              <Tab label="Account" />
              <Tab label="Billing" />
              <Tab label="Security & Privacy" />
              <Tab label="Notifications" />
              <Tab label="Linked Accounts" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <>
              <Typography variant="h6">Profile</Typography>
              <Typography variant="subtitle2">Modify information about your account</Typography>
              <ProfilePreview>
                <ProfileBannerPreview />
                <ProfileAvatarPreview>
                  <Avatar
                    alt="Account Avatar"
                    src={userProfileData.avatar}
                    sx={{
                      width: '125px',
                      height: '125px',
                      border: '8px solid #1f1f23',
                      cursor: 'pointer',
                      filter: 'brightness(' + brightnessAvatar + ')',
                    }}
                    onMouseEnter={() => handleAvatarHover(0.6)}
                    onMouseLeave={() => handleAvatarHover(1)}
                  />
                  <Typography
                    variant="subtitle2"
                    color={THEME.IMPORTANT_TEXT}
                    fontWeight="bold"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      pointerEvents: 'none',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      visibility: brightnessAvatar === 1 ? 'hidden' : 'visible',
                    }}
                  >
                    {t('change_avatar')}
                  </Typography>
                </ProfileAvatarPreview>
                <ProfileInfo>
                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('username')}
                      </Typography>
                      <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                        {userProfileData.username}
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>Edit</SecondaryButton>
                  </ProfileParameter>

                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('password')}
                      </Typography>
                      <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                        *****************
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>Edit</SecondaryButton>
                  </ProfileParameter>
                  <ProfileParameter>
                    <div>
                      <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
                        {t('email')}
                      </Typography>
                      <Typography variant="body2" color={THEME.IMPORTANT_TEXT}>
                        {userProfileData.email}
                      </Typography>
                    </div>
                    <SecondaryButton sx={{ fontSize: '14px' }}>Edit</SecondaryButton>
                  </ProfileParameter>
                </ProfileInfo>
              </ProfilePreview>
              <Typography variant="h6" style={{ marginTop: '1rem' }}>
                {t('account_removal')}
                {/* Delete account */}
              </Typography>
              <Typography variant="subtitle2">
                {t('disable_explanation')}
                {/* Once you delete your account, this process cannot be reversed. */}
              </Typography>
              <StyledProfilePreview>
                <Stack direction="row" spacing={2}>
                  <Button color="error" variant="contained">
                    {t('disable_account')}
                  </Button>
                  <Button color="error" variant="outlined" sx={{ color: THEME.IMPORTANT_TEXT }}>
                    {t('delete_account')}
                  </Button>
                </Stack>
              </StyledProfilePreview>
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>{t('billing')}</>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <>
              <Typography variant="h6">{t('security')}</Typography>
              <Typography variant="subtitle2">{t('security_note')}</Typography>
              <StyledProfilePreview>
                <Typography variant="subtitle2">{t('password')}</Typography>
                <Typography variant="subtitle2">{t('two_factor_athentication')}</Typography>
              </StyledProfilePreview>
              <Typography variant="h6" style={{ marginTop: '1rem' }}>
                {t('privacy')}
              </Typography>
              <Typography variant="subtitle2">{t('adjust_privacy')}</Typography>
              <StyledProfilePreview>
                <Typography variant="subtitle2">{t('safe_file_sharing')}</Typography>
                <Typography variant="subtitle2">{t('who_adds_friend')}</Typography>
                <Typography variant="subtitle2">{t('blocked_users')}</Typography>
              </StyledProfilePreview>
            </>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <>{t('notifications')}</>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <>{t('linked_accounts')}</>
          </TabPanel>
        </Box>
      </SettingsContainer>
    </SettingsWrapper>
  );
};

export default SettingsPanel;
