import styled from 'styled-components';

import { Box } from '@mui/material';

import { THEME } from 'utils/constants';

export const ChatboxWrapper = styled(Box)`
  flex-shrink: 0;
  z-index: 4;
`;

export const ChatboxContainer = styled.div`
  background-color: ${THEME.PRIMARY}; //rgb(31, 35, 38);
  height: 100%;
  border-left: 1px solid ${THEME.SECONDARY};
`;

export const ChatboxHeader = styled(Box)`
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 0 200px rgb(6 106 183 / 50%);
`;

export const GuestLogin = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
`;

export const TermsContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1rem;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;

export const LoginModalWrapperStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
};
