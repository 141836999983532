import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Stack, Zoom, Tabs, Tab } from '@mui/material';
import { Add, Person, Explore } from '@mui/icons-material';

import { GlobalContext } from 'App';
import { getRoomsOfUser, IRoomPanelData, createRoom, deleteRoom } from 'api/room';
import { CenteredDiv } from 'style/common';
import { THEME, PremiumTypes } from 'utils/constants';
import ConfirmationModal from 'components/common/ConfirmationModal';

import RoomTile from './RoomTile';
import { Container, CreateRoomButton } from './style';

const PAPER_ELEVATION = 6;

const FriendIcon = () => (
  <svg className="linkButtonIcon-Mlm5d6" aria-hidden="false" width={24} height={24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M0.5,0 L0.5,1.5 C0.5,5.65 2.71,9.28 6,11.3 L6,16 L21,16 L21,14 C21,11.34 15.67,10 13,10 C13,10 12.83,10 12.75,10 C8,10 4,6 4,1.5 L4,0 L0.5,0 Z M13,0 C10.790861,0 9,1.790861 9,4 C9,6.209139 10.790861,8 13,8 C15.209139,8 17,6.209139 17,4 C17,1.790861 15.209139,0 13,0 Z"
        transform="translate(2 4)"
      />
      <path d="M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z M0,0 L24,0 L24,24 L0,24 L0,0 Z" />
    </g>
  </svg>
);

const RoomsPanel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userProfileData } = useContext(GlobalContext);
  const [rooms, setRooms] = useState<IRoomPanelData[]>(null);

  const [tabValue, setTabValue] = useState(0);

  const [roomToDelete, setRoomToDelete] = useState('');

  const fetchData = async () => {
    const data = await getRoomsOfUser();
    if (data != null) {
      setRooms(data);
    } else {
      setRooms([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createRoomAndRedirect = async () => {
    if (userProfileData.premium_type === PremiumTypes.NONE && rooms.length >= 1) {
      alert('pay');
    } else {
      const redirectUrl = await createRoom();
      history.push(redirectUrl);
    }
  };

  return (
    <>
      <Tabs
        orientation="vertical"
        value={tabValue}
        onChange={(e, n) => setTabValue(n)}
        sx={{ bgcolor: THEME.TERTIARY }}
      >
        <Tab icon={<Person />} label="You" sx={{ textTransform: 'none' }} />
        <Tab icon={<FriendIcon />} label="Friends" sx={{ textTransform: 'none' }} />
        <Tab icon={<Explore />} label="Public" sx={{ textTransform: 'none' }} />
      </Tabs>
      <div style={{ flex: '1', overflow: 'auto', height: '100%', maxHeight: '100%' }}>
        <Container>
          {tabValue === 0 ? (
            <>
              {rooms == null ? (
                <>loading,,,</>
              ) : rooms.length === 0 ? (
                <Stack direction="row" justifyContent="center" alignItems="center" padding={4}>
                  <Typography variant="body1" fontWeight="bold">
                    You don't have any rooms, create your first one!
                  </Typography>
                </Stack>
              ) : (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    overflowY: 'auto',
                  }}
                >
                  {rooms.map((room: IRoomPanelData) => (
                    <RoomTile {...room} setRoomToDelete={setRoomToDelete} />
                  ))}
                </Grid>
              )}
              <ConfirmationModal
                isVisible={roomToDelete.length > 0}
                toggle={() => setRoomToDelete('')}
                title="Delete Room Confirmation"
                message="Are you sure you want to delete this room? This action is irreversible."
                onConfirmCbk={() => {
                  deleteRoom(roomToDelete).then(() => fetchData());
                  setRoomToDelete('');
                }}
                onCancelCbk={() => setRoomToDelete('')}
              />
              <Zoom timeout={200} unmountOnExit in>
                <CreateRoomButton elevation={PAPER_ELEVATION} onClick={createRoomAndRedirect}>
                  <CenteredDiv>
                    <Typography sx={{ color: 'inherit' }} fontWeight="bold" display="flex" alignItems="center">
                      <Add sx={{}} />
                      Create Room
                    </Typography>
                  </CenteredDiv>
                </CreateRoomButton>
              </Zoom>
            </>
          ) : tabValue === 1 ? (
            <>
              <Stack direction="row" justifyContent="center" alignItems="center" padding={4}>
                <Typography variant="body1" fontWeight="bold">
                  {t('no_friends_online')}
                </Typography>
              </Stack>
            </>
          ) : tabValue === 2 ? (
            <>
              <Stack direction="row" justifyContent="center" alignItems="center" padding={4}>
                <Typography variant="body1" fontWeight="bold">
                  {t('no_public_room_available')}
                </Typography>
              </Stack>
            </>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default RoomsPanel;
