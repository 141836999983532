import styled from 'styled-components';

import { Box } from '@mui/material';

export const RoomContainer = styled.div`
  height: 100%;
  background: black;
  overflow: hidden;
`;

export const VideoPlayerWrapper = styled(Box)`
  background-color: #000000;
  min-width: 0;
  height: auto;
  display: flex;
`;
