import { Typography, Grid, Box } from '@mui/material';
import { Done, CloseSharp } from '@mui/icons-material';
import { red, green, yellow } from '@mui/material/colors';

import Logo from 'components/common/Logo';
import W2GLogoImg from 'assets/w2g-logo.png';
import KastLogoImg from 'assets/kast-logo.png';
import { isAnyOf } from 'utils/commonUtils';

import { Container } from './style';
import { useTranslation } from 'react-i18next';

const FEATURES_I18_LABELS = [
  'torrents',
  'youtube',
  'twitch',
  'soundcloud',
  'spotify',
  'screen_share',
  'upload_media',
  'file_sharing_limit',
  'linked_accounts',
  'no_ads',
];

const FEATURE_LIST = [
  // torrents
  { synther: true, w2g: false, kast: false },
  // youtube
  { synther: true, w2g: true, kast: true },
  // twitch
  { synther: true, w2g: true, kast: false },
  // soundcloud
  { synther: true, w2g: true, kast: false },
  // spotify
  { synther: 'Coming Soon', w2g: false, kast: false },
  // screenshare
  { synther: true, w2g: true, kast: 'Paid' },
  // upload media
  { synther: 'Coming Soon', w2g: false, kast: false },
  // file sharing limit
  { synther: '50MB', w2g: false, kast: false },
  // linked accounts
  { synther: 'Coming Soon', w2g: false, kast: false },
  // no ads
  { synther: true, w2g: 'Paid', kast: 'Paid' },
];

const FeatureResult = (props: { value: string | boolean | JSX.Element }) =>
  props.value === true ? (
    <Done sx={{ color: green[400] }} />
  ) : props.value === false ? (
    <CloseSharp sx={{ color: red[400] }} />
  ) : typeof props.value === 'string' ? (
    <Typography sx={{ color: isAnyOf(props.value, ['50MB']) ? green[400] : yellow[200] }}>{props.value}</Typography>
  ) : (
    props.value
  );

const SubscriptionDetails = () => {
  const { t } = useTranslation();

  return (
    <Container style={{ maxWidth: '1000px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="center">
          <Grid item xs={6} md={3}>
            <Grid container>
              <Grid item xs={12} sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', height: '100px' }} />
              {FEATURES_I18_LABELS.map((feature) => (
                <Grid
                  key={`${feature}-label`}
                  item
                  xs={12}
                  p={2}
                  sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', minHeight: '57px' }}
                  justifyContent="center"
                >
                  <Typography>{t(feature)}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} lg={3} sx={{ outline: '4px solid rgb(69 130 193)', borderRadius: '16px' }}>
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', height: '100px' }}
              >
                <Logo size="medium" onlyMark={window.innerWidth < 600} removeLink />
              </Grid>
              {FEATURE_LIST.map((feature, index) => (
                <Grid
                  key={`synther-${index}`}
                  item
                  xs={12}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', minHeight: '57px' }}
                >
                  <FeatureResult value={feature.synther} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} xs={12} md={12} lg={3}>
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', height: '100px' }}
              >
                {/* 1148 */}
                <img src={W2GLogoImg} width="100%" style={{ padding: '1rem' }} />
              </Grid>
              {FEATURE_LIST.map((feature, index) => (
                <Grid
                  key={`$w2g-${index}`}
                  item
                  xs={12}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}
                >
                  <FeatureResult value={feature.w2g} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} md={12} lg={3}>
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)', height: '100px' }}
              >
                <img src={KastLogoImg} width="100%" style={{ padding: '1rem' }} />
              </Grid>
              {FEATURE_LIST.map((feature, index) => (
                <Grid
                  key={`kast-${index}`}
                  item
                  xs={12}
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}
                >
                  <FeatureResult value={feature.kast} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SubscriptionDetails;
