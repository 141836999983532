import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Slide, TextField, Grid, Typography } from '@mui/material';
import { ScreenShare, Close, ArrowBack } from '@mui/icons-material';

import { THEME } from 'utils/constants';
import { YouTubeSVG, YouTubeWhiteSVG, TwitchSVG, SpotifySVG, SoundCloudSVG } from 'assets/SVG';
import WEB_TORRENT_LOGO from 'assets/icons/webtorrent.png';
import { MediaTypeEnum } from 'views/Room/Room';

import YouTubeBrowse from './Youtube/YouTube';
import {
  ModalContainer,
  SearchBarWrapper,
  StyledButton,
  TwitchButton,
  SpotifyButton,
  SoundcloudButton,
  ScreenshareButton,
  CustomSVGWrapper,
} from './style';
import IBrowseModal from './types';

const BrowseModal = (props: IBrowseModal) => {
  const { t } = useTranslation();
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [didSelectMedia, setDidSelectMedia] = useState(false);
  const [whichHighlighted, setWhichHighlighted] = useState('');

  const selectMedia = (newMediaType: MediaTypeEnum) => {
    setDidSelectMedia(true);
    setWhichHighlighted('');
    props.onSwitchMediaCbk(newMediaType);
  };

  return (
    <Slide direction="up" in={props.isVisible} mountOnEnter unmountOnExit>
      <ModalContainer>
        <SearchBarWrapper>
          <IconButton
            onClick={() => setDidSelectMedia(false)}
            size="medium"
            style={{ visibility: didSelectMedia ? 'visible' : 'hidden' }}
          >
            <ArrowBack style={{ color: THEME.TEXT }} />
          </IconButton>
          {didSelectMedia ? (
            <TextField
              value={props.videoValue}
              placeholder={`Search ${props.currentMedia}`}
              variant="outlined"
              style={{ width: '100%', ...(isMobileDevice ? {} : { margin: '0 10rem' }) }}
              onChange={props.onURLChangeCbk}
              onKeyDown={props.onChangeVideoCbk}
              sx={{
                input: {
                  color: THEME.TEXT,
                  background: THEME.SECONDARY, //"#272C38",
                  borderRadius: '0.5rem',
                },
                label: {
                  color: THEME.TEXT,
                },
              }}
            />
          ) : (
            <TextField
              value={props.videoValue}
              placeholder="Paste a direct link to the video"
              variant="outlined"
              style={{ width: '100%', ...(isMobileDevice ? {} : { margin: '0 10rem' }) }}
              onChange={props.onURLChangeCbk}
              onKeyDown={props.onChangeVideoCbk}
              sx={{
                input: {
                  color: '#fff',
                  background: '#272C38',
                  borderRadius: '0.5rem',
                },
                label: {
                  color: '#ebebeb',
                },
              }}
            />
          )}
          <IconButton onClick={() => props.toggle(false)} size="large">
            <Close style={{ color: '#fff' }} />
          </IconButton>
        </SearchBarWrapper>
        {didSelectMedia ? (
          <>
            {props.currentMedia === 'youtube' && <YouTubeBrowse />}
            {props.currentMedia === 'twitch' && <div>twitch</div>}
            {props.currentMedia === 'netflix' && <div>netflix</div>}
            {props.currentMedia === 'spotify' && <div>spotify</div>}
            {props.currentMedia === 'soundcloud' && <div>soundcloud</div>}
            {props.currentMedia === 'webtorrent' &&
              {
                /* <WebTorrentBrowse
              ref={{ player, socket }}
              roomId={props.match.params.id}
              setTracks={setTracks}
              value={videoValue}
              onChange={onURLChange}
              onKeyDown={onChangeVideo}
            /> */
              }}
          </>
        ) : (
          <Grid container spacing={2} style={{ padding: isMobileDevice ? '1rem' : '1rem 10rem' }}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <StyledButton
                onClick={() => selectMedia(MediaTypeEnum.YouTube)}
                onMouseEnter={() => setWhichHighlighted('youtube')}
                onMouseLeave={() => setWhichHighlighted('')}
              >
                {whichHighlighted === 'youtube' ? (
                  <YouTubeSVG width={350} height={350} />
                ) : (
                  <YouTubeWhiteSVG width={350} height={350} />
                )}
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TwitchButton
                onClick={() => selectMedia(MediaTypeEnum.Twitch)}
                onMouseEnter={() => setWhichHighlighted('twitch')}
                onMouseLeave={() => setWhichHighlighted('')}
              >
                <TwitchSVG width={350} height={350} darkMode={whichHighlighted === 'twitch'} />
              </TwitchButton>
            </Grid>
            {/* <Grid item xs={4}>
              <Button onClick={selectMedia} value="netflix" style={{ width: "100%", background: "#fff" }}>
                <NetflixSVG width={400} height={400} />
              </Button>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SpotifyButton
                onClick={() => selectMedia(MediaTypeEnum.Spotify)}
                onMouseEnter={() => setWhichHighlighted('spotify')}
                onMouseLeave={() => setWhichHighlighted('')}
              >
                <SpotifySVG width={350} height={350} darkMode={whichHighlighted === 'spotify'} />
              </SpotifyButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SoundcloudButton
                onClick={() => selectMedia(MediaTypeEnum.SoundCloud)}
                onMouseEnter={() => setWhichHighlighted('soundcloud')}
                onMouseLeave={() => setWhichHighlighted('')}
              >
                <SoundCloudSVG width={400} height={350} darkMode={whichHighlighted === 'soundcloud'} />
              </SoundcloudButton>
            </Grid>
            {!isMobileDevice && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <StyledButton onClick={() => selectMedia(MediaTypeEnum.WebTorrent)} style={{ height: '100%' }}>
                    <CustomSVGWrapper>
                      <img src={WEB_TORRENT_LOGO} alt={'WebTorrent'} style={{ padding: '1rem', height: '100px' }} />
                      <Typography variant="h4" fontWeight={'bold'} style={{ color: '#ed324b' }}>
                        {t('webtorrent')}
                      </Typography>
                    </CustomSVGWrapper>
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ScreenshareButton
                    onClick={() => props.onSwitchMediaCbk(MediaTypeEnum.ScreenShare)}
                    style={{ height: '100%' }}
                    onMouseEnter={() => setWhichHighlighted('screenshare')}
                    onMouseLeave={() => setWhichHighlighted('')}
                  >
                    <CustomSVGWrapper>
                      <ScreenShare
                        fontSize="large"
                        style={{
                          color: whichHighlighted === 'screenshare' ? '#FFF' : 'rgb(25 118 210)',
                          width: '100px',
                          height: '100px',
                          padding: '1rem',
                        }}
                      />
                      <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        color={whichHighlighted === 'screenshare' ? '#FFF' : 'none'}
                      >
                        {t('screen_share')}
                      </Typography>
                    </CustomSVGWrapper>
                  </ScreenshareButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </ModalContainer>
    </Slide>
  );
};

export default BrowseModal;
